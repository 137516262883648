<template>
  <div>
    <van-nav-bar :title="pageTitle" left-arrow right-text="主页" @click-left="toClassHourList" @click-right="toStudent" />
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="initDataList">
      <van-cell v-for="item in dataList" :key="item.id" :title="'消耗：'+ item.consumeClassHour + '课时'">
        <template #label>
          <div>消费时间：{{ item.createTime }}</div>
        </template>
        <van-tag v-if="item.adjustFlag" type="danger">校准</van-tag>
      </van-cell>
    </van-list>
  </div>
</template>
<script>
import guardianApi from '@/api/guardian'
export default {
  data() {
    return {
      appId: '',
      studentId: '',
      classHourId: '',
      dataList: [],
      loading: false,
      maxId: null,
      finished: false,
      pageTitle: ''
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.studentId = this.$route.params.studentId
    this.classHourId = this.$route.params.classHourId
    this.pageTitle = this.$route.query.courseTitle + '-' + this.$route.meta.title
  },
  mounted() {},
  methods: {
    initDataList() {
      guardianApi.classHourLogList({ maxId: this.maxId, studentId: this.studentId, classHourId: this.classHourId }).then(res => {
        if (res.succ) {
          let items = res.data.items
          if (items.length === 0) {
            this.finished = true
          } else {
            for (let i = 0; i < items.length; i++) {
              this.dataList.push(items[i])
            }
            this.loading = false
            this.maxId = items[items.length - 1].id
          }
        }
      })
    },
    toClassHourList() {
      this.$router.push(`/${this.appId}/guardian/classHour/list`)
    },
    toStudent() {
      this.$router.push(`/${this.appId}/guardian`)
    }
  }
}
</script>
<style lang="less" scoped>
</style>
